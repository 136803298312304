import { Injectable } from '@angular/core';
import * as crypto from 'crypto-js';

@Injectable({
  providedIn: 'root',
})
export class AESEncryptDecryptServiceService {
  constructor() {}

  // QA/UAT secret key //svb = Hex + 10 => binary => base64
  encryptSecretKey =
    'AOdgJMOzJS+H9hH63/GHwMeKSx7dOXKLTAQq0g452n+Re2XtOVxM0t7/+FvB+5KN';

  encryptData(data?: any) {
    try {
      return crypto.AES.encrypt(
        JSON.stringify(data),
        this.encryptSecretKey,
      ).toString();
    } catch (e: any) {
      return e.toString();
    }
  }

  decryptData(data: any) {
    try {
      const bytes = crypto.AES.decrypt(data, this.encryptSecretKey);
      if (bytes.toString()) {
        return JSON.parse(bytes.toString(crypto.enc.Utf8));
      }
      return data;
    } catch (e) {
      return e;
    }
  }
}
